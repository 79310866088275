import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { IoCopyOutline, IoCheckmarkSharp } from "react-icons/io5";
import { AiOutlineLike, AiFillLike, AiOutlineDislike, AiFillDislike } from "react-icons/ai";
import { marked } from "marked";
import './chat.css'

const Message = ({ type, text, role, timestamp, callStatus, messageId, feedback }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [visible, setVisible] = useState(type === "user");
  const [displayedText, setDisplayedText] = useState("");
  const messageEndRef = useRef(null);

  const safeText = typeof text === "string" ? text : "";

  const processedText = safeText.includes(
    "There is no information available regarding"
  )
    ? "I'm sorry, I couldn't find the information you're looking for. Please feel free to reach out directly at Email: contact@TalentBridge.fi or Phone: +358 40 571 0112 for more assistance."
    : safeText;

  // Format the timestamp
  const formatTime = (timestamp) => {
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return "Invalid Date";
      }
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    } catch (error) {
      return "Invalid Date";
    }
  };

  const handleCopy = (content) => {
    // Create a temporary element to strip HTML tags
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content; // Set the HTML content
    const plainText = tempElement.innerText || tempElement.textContent; // Extract plain text
  
    // Copy plain text to the clipboard
    navigator.clipboard.writeText(plainText).then(
      () => {
        console.log("Copied to clipboard:", plainText);
      },
      (error) => {
        console.error("Failed to copy text:", error);
      }
    );
  };
  
  const handleButtonClick = (type) => {
    setActiveButton(type); // Set the active button type
    if (type === "copy") {
      handleCopy(role === "assistant" ? styledText : safeText);
    } else {
      handleFeedback(messageId, type);
    }
  };

  useEffect(() => {
    setVisible(true);
    if (role === "assistant") {
      let currentIndex = 1;
      const interval = setInterval(() => {
        setDisplayedText((prev) => prev + processedText[currentIndex]);
        currentIndex++;
        if (currentIndex >= processedText.length - 1) {
          clearInterval(interval);
        }
      }, 2);
      return () => clearInterval(interval);
    } else {
      setDisplayedText(processedText);
    }
  }, [role, processedText]);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayedText]);

  const formattedText = marked(displayedText, {
    renderer: new marked.Renderer(),
    gfm: true,
  });

  const styledText = formattedText.replace(
    /<a href="(.*?)">(.*?)<\/a>/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:#2467a3;text-decoration:underline;">$2</a>'
  );
  const handleFeedback = async (messageId, feedback) => {
    try {
      const response = await axios.post('https://api.susan.fi/api/feedback', { messageId, feedback });
      console.log(response.data.message); // Show success message
    } catch (error) {
      console.error('Error updating feedback:', error);
    }
  };

  if (!visible || !processedText) return null;

  return (
    safeText !== "error" &&
    safeText !== "voice" &&
    safeText !== "Sorry, there was an error processing your request." && (
      <>
       <div className={`message-wrapper ${role}`}>
      {/* Message Bubble */}
      <div className={`message-bubble ${role}`}>
        {role === "assistant" ? (
          <div className="message-content" dangerouslySetInnerHTML={{ __html: styledText }} />
        ) : (
          <p className="message-content">{safeText}</p>
        )}
      </div>
      {
        role === "assistant" ?  <div className={`like-dislike assistant`}>
   <button
        aria-label="Copy message"
        onClick={() => handleButtonClick("copy")}
      >
        {activeButton === "copy" ? <IoCheckmarkSharp /> : <IoCopyOutline />}
      </button>

      {/* Like Button */}
      <button
        aria-label="Like message"
        onClick={() => handleButtonClick("like")}
      >
        {activeButton === "like" ? <AiFillLike /> : <AiOutlineLike />}
      </button>

      {/* Dislike Button */}
      <button
        aria-label="Dislike message"
        onClick={() => handleButtonClick("dislike")}
      >
        {activeButton === "dislike" ? <AiFillDislike /> : <AiOutlineDislike />}
      </button>
        </div> : false
      }
      <div className={`message-timestamp ${role}`}>{formatTime(timestamp)}</div>
      <div ref={messageEndRef} />
    </div>
</>
    )
  );
};

export default Message;
