import React, { useEffect, useState } from "react";
import { HiDotsVertical, HiX } from "react-icons/hi";

import Avatar from "../../assets/images/avatar.jpg";
import Susan from "../../assets/images/susan.png";

function Index({ visibleAvatar, isDivVisible, Close }) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    let timer;
    if (visibleAvatar) {
      timer = setInterval(() => {
        setSeconds((prev) => {
          if (prev === 59) {
            setMinutes((min) => min + 1);
            return 0;
          }
          return prev + 1;
        });
      }, 1000);
    } else {
      clearInterval(timer);
      setSeconds(0);
      setMinutes(0); // Reset timer if `visibleAvatar` becomes false
    }

    return () => clearInterval(timer); // Cleanup on component unmount or `visibleAvatar` change
  }, [visibleAvatar]);
  return (
    <>
      <div className="flex items-center justify-between mb-4 p-4 bg-green shadow-lg rounded-lg">
        {visibleAvatar ? (
          <div
            className="relative pulse-circle bg-blue-250 border border-gray-300 rounded-full "
            style={{ width: "30px", height: "30px", color:'white' }}
          >
            <img
              src={Avatar}
              alt="Description of the avatar"
              className="w-[90%] h-[90%] object-cover rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
            
            />
          </div>
        ) : isDivVisible ? (
          <span
          style={{
            color: "white", // Ensures the text color is white
            fontSize: "14px", // Optional: Adjust font size for better readability
            fontWeight: "bold", // Optional: Make the text bold
          }}
        >
          Ringing...
        </span>
        ) : (
          <div className="flex items-center space-x-2">
            <img src={Susan} alt="Susan" style={{ width: "30px" }} />
            <span className="text-lg font-bold" style={{color:'white'}}>SUSAN</span>
          </div>
        )}
        {visibleAvatar && (
          <span style={{color:'white'}}>
          {minutes}:{seconds}
          </span>

        )}
        {visibleAvatar && (
          <>
            <div class="pulse-container">
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
            </div>
          </>
        )}
        {!visibleAvatar && (
          <div className="flex space-x-2">
            {/* <HiDotsVertical className="text-xl cursor-pointer" /> */}
            {/* <HiX className="text-xl cursor-pointer" onClick={Close} /> */}
          </div>
        )}
      </div>
      {isDivVisible && (
        <>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="flex justify-center items-center relative">
              <div className="pulse-circle bg-blue-100 border border-gray-300 rounded-full w-[200px] h-[200px] absolute" />
              <div className="pulse-circle bg-blue-200 border border-gray-300 rounded-full w-[160px] h-[160px] absolute" />
              <div className="pulse-circle bg-blue-250 border border-gray-300 rounded-full w-[120px] h-[120px] absolute">
                <img
                  src={Avatar}
                  alt="Avatar Icon"
                  className="w-[90%] h-[90%] object-cover rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Index;
