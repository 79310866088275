import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import callStart from "../assets/Audio/call-start.mp3";
import callEnd from "../assets/Audio/call-end.mp3";
import send from "../assets/Audio/send.mp3";
import recieve from "../assets/Audio/recieve.mp3";
import "./chat.css";
import Header from "../Chat/Header";
import Messages from "../Chat/Messages";
import FormInput from "../Chat/FormInput";
import socket from "./socket";

function Chat(props) {
  const [questions, setQuestions] = useState([]);
  const [botBusy, setBotBusy] = useState(false);
  const [textFieldBusy, setTextFieldBusy] = useState(false);
  const [loading, setLoading] = useState(false);
  const audioInstancesRef = useRef([]);
  const [isDivVisible, setDivVisible] = useState(false);
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userStream, setUserStream] = useState([]);
  const timeoutRefTTs = useRef(null);
  const timeoutRefCallEnd = useRef(null);
  

  const [textValue, setTextValue] = useState("");
  const audioInstanceRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const callStartRef = useRef(null);
  const callEndRef = useRef(null);
  const timeoutRef = useRef(null);
  const messageEndRef = useRef(null);

  const getReadingTime = (text, speakingRate = 1.0) => {
    const wordsPerMinute = 160 * speakingRate; // Adjust WPM based on speakingRate
    const wordsPerSecond = wordsPerMinute / 60;
    const wordCount = text.split(/\s+/).length; // Count words
    const estimatedTime = wordCount / wordsPerSecond;
    return Math.ceil(estimatedTime); // Round up to the nearest second
  };

  const googleCloudTTS = async (text) => {
    if (text === "stop") {
      clearTimeout(timeoutRefTTs.current);
      clearTimeout(timeoutRefCallEnd.current);

      audioInstancesRef.current.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = "";
        }
      });
      audioInstancesRef.current = [];
  
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
  
      return;
    }
  
    // Stop and clear any currently playing audio
    if (audioInstancesRef.current.length > 0) {
      audioInstancesRef.current.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = "";
        }
      });
      audioInstancesRef.current = [];
    }
  
    const cleanedText = text
      .replace(/<[^>]+>/g, "") // Removes HTML tags
      .replace(/\\||/g, "") // Removes backslashes and pipe symbols
      .replace(/https?:\/\/\S+/g, "") // Removes URLs
      .replace(/\[.?\]\(.?\)/g, "") // Removes markdown links
      .replace(/#{1,}/g, "") // Removes markdown headers
      .replace(/\*/g, ""); // Removes asterisks
  
    const apiKey = "AIzaSyBs_GzMmIyN-MOFUnOVCiPGetlF0aCLgIM";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const config = {
      input: { text: cleanedText },
      voice: { languageCode: "en-US", name: "en-US-Journey-F" },
      audioConfig: { audioEncoding: "MP3" },
    };
  
    try {
      const response = await axios.post(url, config);
      const audioContent = response.data.audioContent;

      if (audioContent) {
        const speakingRate = 1.0; // Default rate (adjust if needed)
        const estimatedTime = getReadingTime(cleanedText, speakingRate);
        console.log(`Estimated reading time: ${estimatedTime} seconds`);
         
        if(text !== `Are you still there? I haven't heard from you. I'll end the call soon unless you respond.`) {
        if (timeoutRefTTs.current || timeoutRefCallEnd.current) {
          clearTimeout(timeoutRefTTs.current);
          clearTimeout(timeoutRefCallEnd.current);
          console.log('speech outside');
        }

        timeoutRefTTs.current = setTimeout(() => {
          googleCloudTTS("Are you still there? I haven't heard from you. I'll end the call soon unless you respond.")
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              role: 'assistant',
              type: 'call',
              text: `## Are you still there? I haven't heard from you. I'll end the call soon unless you respond.`,
              timestamp: new Date().toISOString(),
            },
          ]);
          console.log("Executing endCall()...");
          console.log('(estimatedTime *Follow-up ', estimatedTime);

          if (timeoutRefTTs.current) {
          console.log('speech Inside');
          console.log('(estimatedTime *Follow-up2 ', estimatedTime);

            clearTimeout(timeoutRefTTs.current);
          }
          
        }, (estimatedTime * 1000) + 20000);
        console.log('timeoutRefTTs.current: ', timeoutRefTTs.current);

      timeoutRefCallEnd.current = setTimeout(() => {
          endCall();
          if (timeoutRefCallEnd.current) {
            // clearTimeout(timeoutRefTTs.current);
            clearTimeout(timeoutRefCallEnd.current);
          console.log('(estimatedTime *Call2 ', estimatedTime);

          }

          console.log('(estimatedTime *EndCall ', estimatedTime);
        }, (estimatedTime * 1000) + 30000)
        console.log('timeoutRefCallEnd.current',timeoutRefCallEnd.current);
      }

        const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
  
        // Stop any previous audio
        if (audioInstanceRef.current) {
          audioInstanceRef.current.pause();
          audioInstanceRef.current.currentTime = 0;
          audioInstanceRef.current.src = "";
        }
  
        audioInstanceRef.current = audio;
        audioInstancesRef.current.push(audio);
  
        audio.play();
  
        audio.onended = () => {
          console.log("Audio playback finished");
          const index = audioInstancesRef.current.indexOf(audio);
          if (index > -1) audioInstancesRef.current.splice(index, 1);
          audioInstanceRef.current = null;
        };
      }
    } catch (error) {
      console.error("Error calling Google Cloud TTS:", error);
    }
  };

  // const elevenLabsTTS = async (text) => {
  //   // Stop and clear any currently playing audio
  //   if (audioInstancesRef.current.length > 0) {
  //     audioInstancesRef.current.forEach((audio) => {
  //       if (audio) {
  //         audio.pause();
  //         audio.currentTime = 0;
  //         audio.src = "";
  //       }
  //     });
  //     audioInstancesRef.current = [];
  //   }

  //   //text = Moving to Finland Relocating to ---- Finland ... involves several steps.;

  //   const cleanedText = text
  //     .replace(/<[^>]+>/g, "")
  //     .replace(/\\||/g, "")
  //     .replace(/https?:\/\/\S+/g, "")
  //     .replace(/\[.?\]\(.?\)/g, "")
  //     .replace(/#{1,}/g, "");

  //   // Prepare the Eleven Labs TTS API request payload
  //   const apiUrl =
  //     "https://api.elevenlabs.io/v1/text-to-speech/aMSt68OGf4xUZAnLpTU8?optimize_streaming_latency=0&output_format=mp3_22050_32";
  //   const apiKey = "sk_edd92d292dc10d87198ecefcf04f37a35162f52b92cc3350"; // Add your Eleven Labs API key here
  //   const requestBody = {
  //     text: cleanedText,
  //     voice_settings: {
  //       stability: 0.5,
  //       similarity_boost: 0.75,
  //       style: 1,
  //     },
  //     model_id: "eleven_flash_v2_5",
  //   };

  //   try {
  //     const response = await axios.post(apiUrl, requestBody, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "xi-api-key": apiKey,
  //       },
  //       responseType: "arraybuffer", // Ensure the response is interpreted as binary data
  //     });

  //     // Convert the ArrayBuffer into a Blob
  //     const audioBlob = new Blob([response.data], { type: "audio/mp3" });

  //     // Create a URL for the audio Blob
  //     const audioUrl = URL.createObjectURL(audioBlob);

  //     if (audioUrl) {
  //       const audio = new Audio(audioUrl);
  //       // console.log('audio: ', audio);
  //       // Stop any previous audio
  //       if (audioInstanceRef.current) {
  //         audioInstanceRef.current.pause();
  //         audioInstanceRef.current.currentTime = 0;
  //         audioInstanceRef.current.src = "";
  //       }

  //       audioInstanceRef.current = audio;
  //       audioInstancesRef.current.push(audio);

  //       audio.play();

  //       // Start monitoring for user speech
  //       monitorUserSpeech(audio);

  //       audio.onended = () => {
  //         console.log("Audio playback finished");
  //         const index = audioInstancesRef.current.indexOf(audio);
  //         if (index > -1) audioInstancesRef.current.splice(index, 1);
  //         audioInstanceRef.current = null;
  //       };
  //     }
  //   } catch (error) {
  //     console.error("Error calling Eleven Labs TTS:", error);
  //   }
  // };

  // const monitorUserSpeech = (audio) => {
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       audio: {
  //         echoCancellation: true,
  //         noiseSuppression: true,
  //         autoGainControl: false,
  //       },
  //     })
  //     .then((stream) => {
  //       const audioContext = new (window.AudioContext ||
  //         window.webkitAudioContext)();
  //       const microphone = audioContext.createMediaStreamSource(stream);
  //       const analyser = audioContext.createAnalyser();
  //       analyser.fftSize = 2048; // Higher FFT size for better frequency resolution
  //       const bufferLength = analyser.frequencyBinCount;
  //       const dataArray = new Uint8Array(bufferLength);

  //       // Connect microphone to analyser
  //       microphone.connect(analyser);

  //       // Initialize thresholds and variables
  //       const volumeThreshold = 15; // Base volume threshold
  //       const speechFrequencyRange = { low: 300, high: 3000 }; // Human speech frequency range in Hz
  //       const speechDurationThreshold = 500; // 500ms of speech detection before reacting
  //       let lastSpeechTime = Date.now();
  //       let isSpeechDetected = false;

  //       const detectSpeech = () => {
  //         analyser.getByteFrequencyData(dataArray);

  //         // Analyze specific frequency ranges for speech detection
  //         const sampleRate = audioContext.sampleRate; // Get the sample rate of the audio context
  //         const startFrequencyIndex = Math.floor(
  //           (speechFrequencyRange.low / sampleRate) * analyser.fftSize
  //         );
  //         const endFrequencyIndex = Math.ceil(
  //           (speechFrequencyRange.high / sampleRate) * analyser.fftSize
  //         );

  //         let totalSpeechFrequencyVolume = 0;
  //         let totalOtherFrequencyVolume = 0;

  //         for (let i = 0; i < bufferLength; i++) {
  //           if (i >= startFrequencyIndex && i <= endFrequencyIndex) {
  //             totalSpeechFrequencyVolume += dataArray[i]; // Sum speech frequencies
  //           } else {
  //             totalOtherFrequencyVolume += dataArray[i]; // Sum other frequencies
  //           }
  //         }

  //         const averageSpeechFrequencyVolume =
  //           totalSpeechFrequencyVolume /
  //           (endFrequencyIndex - startFrequencyIndex + 1);
  //         const averageOtherFrequencyVolume =
  //           totalOtherFrequencyVolume /
  //           (bufferLength - (endFrequencyIndex - startFrequencyIndex + 1));

  //         // console.log("Speech Frequency Volume:", averageSpeechFrequencyVolume);
  //         // console.log("Other Frequency Volume:", averageOtherFrequencyVolume);

  //         if (
  //           averageSpeechFrequencyVolume > volumeThreshold &&
  //           averageSpeechFrequencyVolume > averageOtherFrequencyVolume * 1.2
  //         ) {
  //           // Speech detected if it's louder than both the threshold and other frequencies
  //           if (!isSpeechDetected) {
  //             isSpeechDetected = true;
  //             console.log("Speech detected!");
  //           }
  //           lastSpeechTime = Date.now();
  //         } else {
  //           // If no speech is detected
  //           if (isSpeechDetected) {
  //             const timeSinceLastSpeech = Date.now() - lastSpeechTime;
  //             if (timeSinceLastSpeech > speechDurationThreshold) {
  //               console.log("Speech detected, stopping bot audio...");
  //               audio.pause();
  //               audio.currentTime = 0;
  //               audio.volume = 0.1;

  //               // Clean up resources
  //               stream.getTracks().forEach((track) => track.stop());
  //               analyser.disconnect();
  //               microphone.disconnect();
  //               clearInterval(intervalId);
  //             }
  //           }
  //         }
  //       };

  //       // Run speech detection every 100ms
  //       const intervalId = setInterval(detectSpeech, 100);

  //       // Cleanup when audio ends or pauses
  //       const cleanup = () => {
  //         clearInterval(intervalId);
  //         stream.getTracks().forEach((track) => track.stop());
  //         analyser.disconnect();
  //         microphone.disconnect();
  //       };

  //       audio.onended = cleanup;
  //       audio.onpause = cleanup;
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing microphone:", error);
  //     });
  // };

  const startCall = async () => {
    setVisibleAvatar(true);
    googleCloudTTS("HELLO, Let's chat about your life to Finland...");
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "assistant",
        type: "call",
        text: `## HELLO, Let's chat about your life to Finland...`,
        timestamp: new Date().toISOString(),
      },
    ]);
    socket.emit("detectHumanVoice");
    socket.emit("startTranscription");
    setIsCallActive(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        // if (event.data.size > 0) {
        socket.emit("audioChunk", event.data);
        // }
      };

      mediaRecorderRef.current.start(30);

      const voiceDetectionRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });

      voiceDetectionRecorder.ondataavailable = (event) => {
        socket.emit("humanVoiceChunks", event.data);
      };
      voiceDetectionRecorder.start(100);

      // Cleanup logic
      mediaRecorderRef.current.onstop = () => {
        voiceDetectionRecorder.stop();
      };
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setBotBusy(false);
    }
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);

  };
  const handleQuestionClick = (question) => {
    const audio = new Audio(send);
    audio.play().catch(error => console.error("Error playing sound:", error));
    let type = visibleAvatar ? "call" : "text";
    console.log("textValue, status: ", type);
    setLoading(true);
    // Set the clicked question as input text and directly send it
    // setMessages((prevMessages) => [
    //   ...prevMessages,
    //   { role:'user', type: type, text: question, timestamp:new Date().toISOString() },
    // ]);
    // setUserStream();
    if (type === "call") {
      setUserStream((prevMessages) => [
        {
          text: question,
        },
      ]);
    } else if (type === "text") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "user",
          type: type,
          text: question,
          timestamp: new Date().toISOString(),
        },
      ]);
    }

    socket.emit("userMessage", question, type); // Emit the clicked question
    setTextFieldBusy(true); // Set the text field as busy
    // setIsCallActive(false); // Ensure the call is inactive
    setTextValue(""); // Clear the input field
  };

  const handleTextSubmit = (event) => {
    const audio = new Audio(send);
    audio.play().catch(error => console.error("Error playing sound:", error));
    setLoading(true);
    setTextFieldBusy(true);
    setIsCallActive(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: "text",
        role: "user",
        text: textValue,
        timestamp: new Date().toISOString(),
      },
    ]);
    socket.emit("userMessage", textValue);
    setTextValue("");
  };

  const endCall = () => {
    socket.emit("stopTranscription"); // Ensure server cleans up streams
    clearTimeout(timeoutRefTTs.current);
    clearTimeout(timeoutRefCallEnd.current);
  
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      setDivVisible(false);
    }
  
    setVisibleAvatar(false);
    setIsCallActive(false);
  
    // Stop media recorder stream
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }
  
    // Pause and reset all audio instances
    audioInstancesRef.current.forEach((audio) => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
    audioInstancesRef.current.length = 0;
  
    // Handle call start audio
    if (callStartRef.current) {
      callStartRef.current.pause();
      callStartRef.current.currentTime = 0;
    }
  
    // Handle call end audio with safe play
    if (callEndRef.current) {
      try {
        callEndRef.current.currentTime = 2; // Ensure correct timing
        callEndRef.current.loop = false;
  
        // Safely play the audio
        const playPromise = callEndRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.warn("Play was interrupted:", error);
          });
        }
      } catch (error) {
        console.error("Error playing call end audio:", error);
      }
    }
  
    // Pause the call end audio after a timeout
    setTimeout(() => {
      if (callEndRef.current) {
        callEndRef.current.pause();
        callEndRef.current.currentTime = 0;
      }
    }, 2000);
  };

  const handleCallClick = async () => {
    if (isCallActive) {
      endCall();
      return;
    }

    setDivVisible(true);
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      if (callStartRef.current) {
        callStartRef.current.currentTime = 7;
        callStartRef.current.loop = true;
        callStartRef.current.play();
      }

      timeoutRef.current = setTimeout(() => {
        setDivVisible(false);
        if (callStartRef.current) {
          callStartRef.current.pause();
          callStartRef.current.currentTime = 0;
        }
        startCall();
      }, 3000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    socket.on("botResponse", (response) => {
      console.log("response123: ", response);
      if (response[0].userPrompt) {
        setMessages((prevMessages) => [
          ...prevMessages,
          ...response.map((msg) => ({
            role: "user",
            type: msg.type,
            text: msg.userPrompt,
            timestamp: msg.timestamp,
            // userPrompt: msg.role === "assistant" ? msg.userPrompt : "",
            // id:msg.id
          })),
        ]);
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        ...response.map((msg) => ({
          id: msg.id,
          role: msg.role,
          type: msg.type,
          text: msg.content,
          timestamp: msg.timestamp,
        })),
      ]);
      if (response[0].type === "human" || response[0].role === "human") {
        // setMessages((prevMessages) => [
        //   ...response.map((msg) => ({
        //     type: msg.role === "assistant" ? "bot" : "user",
        //     text: msg.messageUser,
        //     id:msg.id
        //   }))
        // ]);
        setUserStream((prevMessages) => [
          ...response.map((msg) => ({
            text: msg.messageUser,
          })),
        ]);
        googleCloudTTS("stop");
      }

      if (response && response[0] && response[0].questions) {
        setQuestions(response[0].questions);
        setLoading(false);
      }
      // uncomment it
      // let count = 0;
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   ...response.map((msg) => ({
      //     type: msg.role === "assistant" ? "bot" : "user",
      //     text: msg.content,
      //   })),
      // ]);
      // count++

      const assistantMessage = response.find((msg) => msg.role === "assistant");
      if (response[0].role === "user") {
        
        setBotBusy(true);
      }
      if (response[0].role === "assistant") {
        setUserStream("");
        if (response[0].content !== "error") {
          console.log("response[0].content", response[0].content)
        const audio = new Audio(recieve);
        audio.play().catch(error => console.error("Error playing sound:", error));
        }
        
        // console.log("setMessages", messages);
        setBotBusy(false);
        setTextFieldBusy(false);
      }

      if (assistantMessage && response[0].type === "call") {
        setUserStream("");
        // setMessages((prevMessages) => [
        //   ...prevMessages,
        //   ...response.map((msg) => ({
        //     type: msg.role === "assistant" ? "bot" : "user",
        //     text: msg.content,
        //   })),
        // ]);
        // console.log("setMessages", messages);

        setBotBusy(false);
        googleCloudTTS(assistantMessage.content);
        // elevenLabsTTS(assistantMessage.content);
      }
    });
    console.log("messages Reset: ", messages);
    return () => {
      socket.off("botResponse");
    };
  }, []);
  
  // document.addEventListener("contextmenu", (event) => event.preventDefault());

  // // Disable DevTools Shortcuts
  // document.addEventListener("keydown", (event) => {
  //     if (
  //         event.key === "F12" || 
  //         (event.ctrlKey && event.shiftKey && (event.key === "I" || event.key === "J" || event.key === "C")) || 
  //         (event.ctrlKey && event.key === "U")
  //     ) {
  //         event.preventDefault();
  //     }
  // });
  
  return (
    <div
      className="chatbox shadow-lg mx-auto flex justify-center"
      style={{ background: "#0a0e18" }}
    >
      <div
        className="backdrop-blur-md bg-green bg-opacity-30 rounded-lg h-full p-4 flex flex-col"
        style={{ width: "900px", height: "100vh" }} // Full height container
      >
        <Header
          visibleAvatar={visibleAvatar}
          isDivVisible={isDivVisible}
          Close={props.Close}
        />
        <div
          className="flex-grow overflow-y-auto scrollable-content"
          style={{
            display: "flex",
            flexDirection: "column", // Normal direction (top to bottom)
          }}
        >
          {messages.map((message, index) => (
            <>
              <Messages
                callStatus={visibleAvatar}
                key={index}
                type={message?.type}
                messageId={message?.id}
                text={message?.text}
                role={message?.role}
                timestamp={message?.timestamp}

                // userPrompt={message?.userPrompt}
              />
            </>
          ))}
          {/* ###### */}
          {userStream.length > 0 && (
            <div
              className="flex mb-4 justify-end"
              style={{ justifyContent: "right" }}
            >
              <div
                className="rounded-lg shadow-md bg-blue-500 text-white"
                style={{
                  width: "80%",
                  padding: "1rem",
                  borderRadius: "15px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "15px",
                  backgroundColor: "#2d2d2d",
                  color: "#d5d5d5",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  lineHeight: "1.6",
                }}
              >
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {userStream[0]?.text}
                </p>
                {/* <div ref={messageEndRef} /> */}
              </div>
            </div>
          )}
          {/* ###### */}
          <p style={{ color: "white" }}>
            {/* {console.log('messagesTeeest: ', messages)} */}
          </p>
          <div ref={messageEndRef} /> {/* To keep focus at the end */}
        </div>
        <div style={{ marginTop: "auto" }}>
          {/* welcome messages */}
          {messages?.length === 0 && (
            <div
              style={{ padding: "16px", marginBottom: "20px", color: "white" }}
            >
              <h1
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Let's chat about your life to Finland...
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                I can talk about universities, job market, setting up a
                business, social services...
              </p>

              {/* List of Questions */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: "flex-start", // Align to the left
                  color: "white",
                }}
              >
                {[
                  "What are the top universities in Finland?",
                  "How can I find a job in Finland?",
                  "What are the steps to start a business in Finland?",
                  "How do I access social services?",
                ].map((question, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: "#1b1b1b",
                      fontSize: "12px",
                      border: "1px solid #363636",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      transition: "background 0.3s ease",
                    }}
                    onClick={() => handleQuestionClick(question)}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#2c2c2c")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#1b1b1b")
                    }
                  >
                    <span style={{ color: "white" }}>{question}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* Input and questions at the bottom */}
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px",
              }}
            >
              <div className="loading-rotate">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {!loading && questions && (
            <div className="mt-4 mb-4">
              {/* {console.log("questions1233: ", questions)} */}
              <div className="space-y-2">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px", // Adds spacing between items
                    justifyContent: "flex-end", // Aligns items to the right
                  }}
                >
                  {questions.map((question, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#1b1b1b",
                        fontSize: "12px",
                        border: "1px solid #363636",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                      onClick={() =>
                        handleQuestionClick(
                          question.question.replace(/[-*]/g, "").trim()
                        )
                      }
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "#2c2c2c")
                      } // Hover effect
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#1b1b1b")
                      } // Reset hover effect
                    >
                      <span style={{ color: "white" }}>
                        {question.question.replace(/[-*]/g, "").trim()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <FormInput
            textValue={textValue}
            handleTextChange={handleTextChange}
            handleTextSubmit={handleTextSubmit}
            isTextFieldBusy={textFieldBusy}
            handleCallClick={handleCallClick}
            isCallActive={isCallActive}
          />
        </div>
      </div>
      <audio ref={callStartRef}>
        <source src={callStart} type="audio/mp3" />
      </audio>
      <audio ref={callEndRef}>
        <source src={callEnd} type="audio/mp3" />
      </audio>
    </div>
  );
}

export default Chat;
