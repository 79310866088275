import React from "react";
import CallIcon from "../../assets/images/phone-volume-solid.svg";
import { HiPaperAirplane } from "react-icons/hi";

function index({
  textValue,
  handleTextChange,
  handleTextSubmit,
  handleCallClick,
  isCallActive,
  isTextFieldBusy,
}) {
  // Function to handle Enter key press in the textarea
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && !isTextFieldBusy) {
      event.preventDefault(); // Prevents newline in the textarea
      handleTextSubmit();
    }
  };

  return (
    <div className="mt-auto flex flex-col items-center">
      <div
        className="w-full p-2 rounded-lg"
        style={{ background: "#1b1b1b", border: "1px solid #363636" }}
      >
        <textarea
          placeholder="Type a message..."
          value={textValue}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown} // Add the onKeyDown handler
          className="flex-grow w-full p-2 bg-[#1b1b1b] rounded-l-md focus:outline-none border-none resize-none overflow-hidden"
          rows={2}
          style={{ background: "#1b1b1b", color: "white" }}
        />
        <div className="flex space-x-2 mt-2 justify-between py-10">
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full hover:bg-[#dfd9d9] cursor-pointer ${
              isCallActive ? "blinking-icon" : ""
            }`}
            onClick={handleCallClick}
            style={{ backgroundColor: isCallActive ? "#d20612" : "#15313b" }}
          >
            <img src={CallIcon} alt="Call Icon" style={{ width: "15px" }} />
          </div>
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full hover:bg-[#dfd9d9] cursor-pointer ${
              isTextFieldBusy ? "cursor-not-allowed" : ""
            }`}
            style={{
              backgroundColor: isTextFieldBusy ? "#ccc" : "#15313b",
              pointerEvents: isTextFieldBusy ? "none" : "auto",
            }}
            onClick={!isTextFieldBusy ? handleTextSubmit : undefined}
          >
            <HiPaperAirplane
              className="text-lg transform rotate-90"
              style={{ color: isTextFieldBusy ? "#666" : "white"}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
